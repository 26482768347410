import React from "react"
import htmlSerializer from "../../utils/htmlSerializer"
import { PrismicRichText } from "@prismicio/react"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

const ServiceAccordion = ({ items = [] }) => {
  return (
    <Accordion preExpanded={[0]} className="accordion w-full">
      {items.map((item, index) => {
        return (
          <AccordionItem uuid={index} key={`item-accordion-${index}`}>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__button flex items-center">
                <p className="text-lg md:text-xl font-lato font-semibold text-text-blue px-4">
                  {item.service_title}
                </p>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="py-4">
                <PrismicRichText
                  field={item.service_details.richText}
                  components={htmlSerializer}
                />
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default ServiceAccordion
